import { useState } from 'react';
import { defaultBrand } from '../constants/defaultBrand';
import { BrandContext } from '../contexts/BrandContext';

const BrandProvider = props => {
  const [brand, setBrand] = useState(defaultBrand);
  const [logo, setLogo] = useState('');

  return (
    <BrandContext.Provider value={{ brand, setBrand, logo, setLogo }}>
      {props.children}
    </BrandContext.Provider>
  );
};

export default BrandProvider;
