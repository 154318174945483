import React from 'react';

const ChatBotFooter = props => {
  const { isDemo } = props;

  if (isDemo) {
    return (
      <div className="chatbot-footer">
        <div>Patent Pending</div>
      </div>
    );
  }

  return null;
};

export default ChatBotFooter;
