import Color from 'color';
import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
// https://github.com/Wolox/react-chat-widget
import { addResponseMessage, addUserMessage, toggleWidget, Widget } from 'react-chat-widget';
import botAvatar from '../assets/ellie.svg';
import { MS } from '../constants/botTypes';
import {
  primaryBrandColorVarName,
  primaryBrandFontColorVarName,
  secondaryBrandColorVarName,
  tertiaryBrandColorVarName
} from '../constants/brandColorVariables';
import { ESCAPE_KEY, ESCAPE_KEYCODE } from '../constants/keyCodes';
import { START, TEXT } from '../constants/messageTypes';
import { DEMO_PARAM } from '../constants/queryParams';
import { BrandContext } from '../contexts/BrandContext';
import { DataContext } from '../contexts/DataContext';
import { handleBotWidgetA11y } from '../utils/a11y-helpers';
import { sendMessageToBot } from '../utils/messaging-helpers';
import ChatBotFooter from './ChatBotFooter';
import CustomLauncher from './CustomLauncher';

// Wraps Widget 3rd party component. Handles events, orchestrates sending/receiving messages with the bot back-end.
const ChatBot = props => {
  const { invalidBotId, sessionId, searchParams, botId } = props;
  const { brand, logo } = useContext(BrandContext);
  const { setCurrentFlowIdentifier } = useContext(DataContext);
  const [isOpen, setIsOpen] = useState(true);
  let primaryColor;
  let secondaryColor;
  let tertiaryColor;
  let fontColor;

  useEffect(() => {
    if (invalidBotId) {
      addResponseMessage('The provided bot ID is invalid.');
    } else {
      if (brand.botType === MS) {
        addResponseMessage(`Please wait a moment while we gather your information.`);
      }
      sendMessageToBot(
        START,
        botId,
        setCurrentFlowIdentifier,
        sessionId,
        brand.botType,
        searchParams,
        brand.messagingEndpoint
      );
    }
  }, []);

  useLayoutEffect(() => {
    handleBotWidgetA11y(logo, brand.title, isOpen);
    if (isOpen) {
      setTheme();
    }
  }, [isOpen]);

  const calculateBrandThemeColors = () => {
    primaryColor = Color(brand.primaryColor);
    fontColor = Color(brand.fontColor);
    if (primaryColor.isLight()) {
      secondaryColor = primaryColor.darken(0.5);
      tertiaryColor = secondaryColor.darken(0.5);
      if (tertiaryColor.isDark() && fontColor.isDark()) {
        tertiaryColor = primaryColor.lighten(0.5);
      }
    } else {
      secondaryColor = primaryColor.lighten(0.5);
      tertiaryColor = secondaryColor.lighten(0.5);
      if (tertiaryColor.isLight() && fontColor.isLight()) {
        tertiaryColor = primaryColor.darken(0.5);
      }
    }
    if (tertiaryColor.luminosity() > 0.8) {
      tertiaryColor = Color('gray');
    }
  };

  const getDemoQueryParam = () =>
    searchParams.has(DEMO_PARAM) && searchParams.get(DEMO_PARAM) === 'true';

  const handleKeyDown = e => {
    const isEscapePressed = e.key === ESCAPE_KEY || e.keyCode === ESCAPE_KEYCODE;
    if (isEscapePressed && isOpen) {
      toggleWidget();
      handleToggleClicked(false);
    }
  };

  const handleQuickButtonClicked = buttonValue => {
    addUserMessage(buttonValue);
    sendMessageToBot(TEXT, buttonValue);
  };

  const handleToggleClicked = open => {
    setIsOpen(open);
  };

  const setTheme = () => {
    calculateBrandThemeColors();
    // set root variables for theme to the chatbot config & derived colors
    document.documentElement.style.setProperty(primaryBrandColorVarName, primaryColor.hex());
    document.documentElement.style.setProperty(primaryBrandFontColorVarName, fontColor.hex());
    document.documentElement.style.setProperty(secondaryBrandColorVarName, secondaryColor.hex());
    document.documentElement.style.setProperty(tertiaryBrandColorVarName, tertiaryColor.hex());
  };

  const renderCustomLauncher = toggle =>
    isOpen ? null : <CustomLauncher handleToggle={toggle} key="launch-btn"></CustomLauncher>;

  const showFooter = () => (isOpen ? <ChatBotFooter isDemo={getDemoQueryParam()} /> : null);

  return (
    <div onKeyDown={handleKeyDown}>
      <div className={isOpen ? 'chatbot-container' : 'chatbot-container chatbot-container-closed'}>
        <Widget
          title={brand.title}
          titleAvatar={logo}
          subtitle=""
          profileAvatar={botAvatar}
          autofocus={false}
          showTimeStamp={false}
          handleQuickButtonClicked={handleQuickButtonClicked}
          handleToggle={handleToggleClicked}
          launcher={handleToggle => renderCustomLauncher(handleToggle)}
        />
        {showFooter()}
      </div>
    </div>
  );
};

export default ChatBot;
