import axios from 'axios';
import {
  addResponseMessage,
  addUserMessage,
  renderCustomComponent,
  setQuickButtons,
  toggleMsgLoader
} from 'react-chat-widget';
import Card from '../components/Card';
import { CARD, DATA, PAUSE, REPLY, SAY } from '../constants/messageTypes';
import { handleAdaptiveCardA11y, handleQuickButtonA11y } from './a11y-helpers';

let resetCurrentFlowIdentifier = null;
let SESSION_ID = '';
let BOT_TYPE = '';
let MESSAGING_ENDPOINT = '';
let SEARCH_PARAMS = null;
let PAUSE_OVERRIDE = null;
let INITALIZED = false;

// Messaging helpers in their own file so that we can send jEllie messages from any component that imports the below function

/**
 * sendMessageToBot prepares the POST /messages payload, sends it, and processes the response to render new bot messages
 * Call this function when you need to communicate with jEllie.
 *
 * @param  {string} messageType
 * @param  {any} messageBody
 * @param  {Function} setCurrentFlowIdentifier DataContext setCurrentFlowIdentifier function
 * @param  {string} sessionId
 * @param  {string} botType
 * @param  {URLSearchParams} searchParams
 * @param  {string} messagingEndpoint
 */
export function sendMessageToBot(
  messageType,
  messageBody,
  setCurrentFlowIdentifier = resetCurrentFlowIdentifier,
  sessionId = SESSION_ID,
  botType = BOT_TYPE,
  searchParams = SEARCH_PARAMS,
  messagingEndpoint = MESSAGING_ENDPOINT
) {
  // only need to pass all args on START bot call
  if (!INITALIZED) {
    resetCurrentFlowIdentifier = setCurrentFlowIdentifier;
    SESSION_ID = sessionId;
    BOT_TYPE = botType;
    MESSAGING_ENDPOINT = messagingEndpoint;
    SEARCH_PARAMS = searchParams;
    // use to allow workflow in chatbot to skip the pauses (usually during testing)
    PAUSE_OVERRIDE = SEARCH_PARAMS.get('po');
    INITALIZED = true;
  }

  postNewMessage(
    createMessagePayload({
      message: { type: messageType, body: messageBody },
      sessionId,
      searchParams,
      botType
    })
  );
}

// Send payload to endpoint, handle responses
async function postNewMessage(payload) {
  try {
    const response = await axios.post(MESSAGING_ENDPOINT, payload);
    const messages = response.data.messages;
    processMessageResponse(messages);
  } catch {
    addResponseMessage('Technical difficulties, please try again later.');
  }
}

function processMessageResponse(messages) {
  let messageProcessedCount = 0;

  // need for...of... in order to use break and handle async nature of pauses
  for (const message of messages) {
    const { type, body } = message;
    messageProcessedCount++;

    // Set timer to process rest of msgs, exit the current loop since func will be re-called with sliced msg arr
    if (type === PAUSE && PAUSE_OVERRIDE !== 'true') {
      toggleMsgLoader();
      const remainingMessages = messages.slice(messageProcessedCount);
      setTimeout(() => {
        toggleMsgLoader();
        processMessageResponse(remainingMessages);
      }, body * 1000);
      break;
    }

    switch (type) {
      case CARD:
        handleCard(body);
        break;
      case SAY:
        handleSay(message, body);
        break;
      case REPLY:
        addUserMessage(body);
        break;
      default:
        break;
    }
  }
}

function queryParamsToJson(searchParams) {
  const paramsObj = {};

  for (var paramItem of searchParams.entries()) {
    if (paramsObj[paramItem[0]] !== undefined) {
      paramsObj[paramItem[0]].push(decodeURIComponent(paramItem[1] || ''));
    } else {
      paramsObj[paramItem[0]] = [decodeURIComponent(paramItem[1] || '')];
    }
  }

  return JSON.stringify(paramsObj);
}

export const createMessagePayload = ({ sessionId, message, searchParams, botType }) => {
  // Web channel session, web channel bot message, web channel time zone, web channel bot type, web channel params
  const payload = new URLSearchParams();
  payload.append('wcsid', sessionId);
  payload.append('wcmsg', JSON.stringify(message));
  payload.append('wctz', Intl.DateTimeFormat().resolvedOptions().timeZone);
  if (botType) {
    payload.append('wcbtype', botType);
  }
  if (searchParams.toString() !== '') {
    payload.append('wcparams', queryParamsToJson(searchParams));
  }
  return payload;
};

const handleCard = body => {
  renderCustomComponent(Card, {
    body,
    handleCardActionSubmit: event => sendMessageToBot(DATA, JSON.stringify(event.data))
  });
  setTimeout(() => {
    const msgContainer = document.getElementById('messages');
    if (msgContainer) {
      const allRenderedMessages = msgContainer.getElementsByClassName('ac-adaptiveCard');
      const mostRecentMessage = allRenderedMessages[allRenderedMessages.length - 1];
      handleAdaptiveCardA11y(mostRecentMessage);
    }
  }, 1000);
};

const handleSay = (message, body) => {
  addResponseMessage(body);
  if (message.replies && message.replies.length > 0) {
    setQuickButtons(message.replies);
    handleQuickButtonA11y();
  }
};
