import { datadogRum } from '@datadog/browser-rum';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { BOT_INFO } from '../constants/messageTypes';
import { BrandContext } from '../contexts/BrandContext';
import DataProvider from '../providers/DataProvider';
import { createMessagePayload } from '../utils/messaging-helpers';
import ChatBot from './ChatBot';

// Wraps entire bot. Responsible for getting EH branding data and setting session based state.
// Will not render chatbot until REST calls are finished.
const ChatBotWrapper = ({ sessionId }) => {
  // route params
  const { botId } = useParams();

  // query params
  const [searchParams] = useSearchParams();

  // context
  const { setBrand, setLogo } = useContext(BrandContext);

  // init state
  const [brandNotFound, setBrandNotFound] = useState(false);
  const [canRender, setCanRender] = useState(false);

  useEffect(() => {
    getBotDetailsFromContainer();
    setDatadogContext();
  }, []);

  const getBotDetailsFromContainer = async () => {
    try {
      const response = await axios.get(`./bots/${botId}/index.json`);
      setBrand({ ...response.data });
      getLogo(`./bots/${botId}/logo.png`);
    } catch {
      getDefaultEndpoint();
    }
  };

  const getBotDetailsFromServer = async messagingEndpoint => {
    try {
      const message = { type: BOT_INFO, body: botId };
      const response = await axios.post(
        messagingEndpoint,
        createMessagePayload({ message, searchParams, sessionId })
      );
      const responseBody = response.data.messages[0].body;
      // if BE errors/doesnt find bot, it returns a msg string.
      // JSON.parse() throws if param is not json, so it will be caught below
      const bot = JSON.parse(responseBody);
      const { botType, fontColor, primaryColor, title } = bot;
      setBrand({ botType, fontColor, primaryColor, title, messagingEndpoint });
    } catch {
      setBrandNotFound(true);
    }
    setLogo('./assets/logo.svg');
    setCanRender(true);
  };

  // to make chatbot frontend project agnostic, build information into the dynamic botUUID to infer botType from UUID, set endpoint dynamically
  // or use botype in data.json
  const getDefaultEndpoint = async () => {
    try {
      const endpointResponse = await axios.get(`./assets/messagingEndpoints.json`);
      const endpoint = endpointResponse.data.MEDSYNC_MESSAGING_ENDPOINT;
      getBotDetailsFromServer(endpoint);
    } catch {
      setLogo('./assets/logo.svg');
      setBrandNotFound(true);
      setCanRender(true);
    }
  };

  const getLogo = async location => {
    try {
      await axios.get(location);
      setLogo(location);
    } catch {
      setLogo('./assets/logo.svg');
    }
    setCanRender(true);
  };

  const setDatadogContext = () => {
    datadogRum.setGlobalContext({
      'bot.uuid': botId
    });
  };

  return canRender ? (
    <DataProvider>
      <ChatBot
        botId={botId}
        invalidBotId={brandNotFound}
        sessionId={sessionId}
        searchParams={searchParams}
      />
    </DataProvider>
  ) : null;
};

export default ChatBotWrapper;
