import { useState } from 'react';
import { DataContext } from '../contexts/DataContext';

const DataProvider = props => {
  const [currentFlowIdentifier, setCurrentFlowIdentifier] = useState('');
  const [selectedLocationIndex, setSelectedLocationIndex] = useState();
  const [selectedAppointmentDate, setSelectedAppointmentDate] = useState();
  const [timeInfo, setTimeInfo] = useState();

  return (
    <DataContext.Provider
      value={{
        currentFlowIdentifier,
        setCurrentFlowIdentifier,
        selectedLocationIndex,
        setSelectedLocationIndex,
        selectedAppointmentDate,
        setSelectedAppointmentDate,
        timeInfo,
        setTimeInfo
      }}
    >
      {props.children}
    </DataContext.Provider>
  );
};

export default DataProvider;
