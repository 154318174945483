import React, { useContext } from 'react';
import launchLogo from '../assets/launch.svg';
import { BrandContext } from '../contexts/BrandContext';

const CustomLauncher = ({ handleToggle }) => {
  const { brand } = useContext(BrandContext);

  return (
    <button
      className="rcw-launcher"
      style={{
        color: brand.fontColor,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${launchLogo})`
      }}
      onClick={handleToggle}
    />
  );
};

export default CustomLauncher;
