export const gatherDateElements = parentElement => {
  const els = parentElement.querySelectorAll(`
    #dob_m,
    #dob_y,
    #dob_d,
    #patient-info-submit
  `);

  if (els.length > 0) {
    // add event listener to check if max length of input has been reached yet
    els.forEach((el, index) => {
      el.addEventListener('keyup', function () {
        autoTabInput(el, els[index + 1]);
      });
      el.addEventListener('keydown', function () {
        autoTabInput(el, els[index + 1]);
      });
      el.setAttribute('inputmode', 'numerical');
    });
  }
};

const autoTabInput = (currentEl, nextEl) => {
  const input = currentEl.querySelectorAll('input');
  // only autotab if input not selected to edit
  if (input[0].value && document.getSelection().toString() !== input[0].value.toString()) {
    if (currentEl.id === 'dob_y') {
      if (input[0].value.length === 4) {
        nextEl.querySelectorAll('button')[0].focus();
      }
    } else {
      if (input[0].value.length === 2) {
        nextEl.querySelectorAll('input')[0].focus();
      }
    }
  }
};
