import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import ChatBotWrapper from './components/ChatBotWrapper';
import BrandProvider from './providers/BrandProvider';

const App = () => {
  const SESSION_ID = uuidv4();

  return (
    <BrandProvider>
      <ChatBotWrapper sessionId={SESSION_ID} />
    </BrandProvider>
  );
};

export default App;
