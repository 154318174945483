import { AdaptiveCard } from 'adaptivecards-react';
import React from 'react';

// React component that wraps an AdaptiveCards card component. Handles card events.
const Card = ({ body, handleCardActionSubmit }) => (
  <AdaptiveCard
    payload={body}
    hostConfig={{ fontFamily: 'Open Sans' }}
    onActionSubmit={handleCardActionSubmit}
  />
);

export default React.memo(Card);
