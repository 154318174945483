import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import React from 'react';
import { toggleWidget } from 'react-chat-widget';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import packageInfo from '../package.json';
import App from './App';
import './styles';

const root = createRoot(document.getElementById('ms-chatbot-app'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/:botId" element={<App />}></Route>
    </Routes>
  </BrowserRouter>
);

// https://docs.datadoghq.com/real_user_monitoring/browser/#choose-the-right-installation-method
datadogRum.init({
  applicationId: '7a4de166-1e68-46c1-b28a-724cc0dcd9cf',
  clientToken: 'pubb2f3ae532cefceac2dbd6ec9df31d066',
  site: 'datadoghq.com',
  service: packageInfo.name,
  env: window['DD-ENV'],
  version: packageInfo.version,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input'
});

// https://docs.datadoghq.com/logs/log_collection/javascript/
datadogLogs.init({
  clientToken: 'pubb2f3ae532cefceac2dbd6ec9df31d066',
  site: 'datadoghq.com',
  service: packageInfo.name,
  env: window['DD-ENV'],
  version: packageInfo.version,
  forwardErrorsToLogs: true,
  sessionSampleRate: 100
});

toggleWidget();
